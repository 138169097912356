<template>
  <div class="card" :class="{ 'card-expand': expanded }">
    <div class="card-header">
      {{$t('sms.infos.outgoing-component.title')}}
      <ul class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="changeChart">
            <i v-if="type == 'line'" class="icon dripicons-graph-pie"></i>
            <i v-else-if="type == 'pie'" class="icon dripicons-graph-line"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="fetch">
            <i class="icon dripicons-clockwise"></i>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <i v-if="expanded" class="icon dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="block-el">
      <template v-if="fetched">
        <div class="card-body">
          <div class="row m-0 col-border-xl">
            <div class="col-md-2 p-20">
              <h6>{{$t('sms.infos.outgoing-component.lbl-total')}}</h6>
              <span class="h3 text-primary">{{ total }}</span>
              <template v-if="delivered">
                <h6 class="m-t-20">{{$tc('generic-str.delivered', 2)}}</h6>
                <span class="h3 text-success">{{ delivered }}</span>
              </template>
              <template v-if="undelivered">
                <h6 class="m-t-20">{{$tc('generic-str.unavailable', 2)}}</h6>
                <span class="h3 text-dark">{{ undelivered }}</span>
              </template>
              <template v-if="failed">
                <h6 class="m-t-20">{{$t('generic-str.status.lbl-errors')}}</h6>
                <span class="h3 text-danger">{{ failed }}</span>
              </template>
              <template v-if="sent">
                <h6 class="m-t-20">{{$tc('generic-str.sent-a', 2)}}</h6>
                <span class="h3 text-info">{{ sent }}</span>
              </template>
              <template v-if="sent">
                <h6 class="m-t-20">{{$t('sms.infos.incoming-component.received')}}</h6>
                <span class="h3 text-success">{{ received }}</span>
              </template>
              <template v-if="read">
                <h6 class="m-t-20">Lidas</h6>
                <span class="h3 text-primary">{{ read }}</span>
              </template>
              <template v-if="accepted">
                <h6 class="m-t-20">Pendentes</h6>
                <span class="h3 text-primary" style="color: #cccccc !important;">{{ accepted }}</span>
              </template>
            </div>
            <div class="col-md-10">
              <template v-if="total">
                <line-chart
                  v-if="type === 'line'"
                  :chartdata="line.chartdata"
                  :options="line.options"
                  :height="chartHeight"
                />
                <doughnut-chart
                  v-else-if="type === 'pie'"
                  :chartdata="pie.chartdata"
                  :options="pie.options"
                  :height="chartHeight"
                />
              </template>
              <div v-else class="text-center table-placeholder">
                <i class="far fa-chart-bar font-size-80"></i>
                <h5 class="card-title m-t-20">{{$t('sms.infos.none')}}</h5>
                <p>{{$t('sms.infos.none-msg')}}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
import WhatsService from '@/services/whatsapp.service';
import LineChart from '@/components/LineChart.vue';
import DoughnutChart from '@/components/DoughnutChart.vue';

export default {
  name: 'OutgoingCard',
  components: { LineChart, DoughnutChart },
  props: ['form', 'filters'],
  data() {
    return {
      chartHeight: window.innerWidth >= 768 ? 102 : 468,
      fetched: true,
      expanded: false,
      type: 'line',
      total: 0,
      delivered: 0,
      undelivered: 0,
      received: 0,
      failed: 0,
      sent: 0,
      accepted: 0,
      read: 0,
      line: {
        chartdata: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'HH:mm:ss',
                  },
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 7,
                },
              },
            ],
          },
        },
      },
      pie: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
        },
      },
    };
  },
  created() {
    if (window.innerWidth >= 1920) {
      this.chartHeight = 102;
    } else if (window.innerWidth >= 1280) {
      this.chartHeight = 174;
    } else if (window.innerWidth >= 768) {
      this.chartHeight = 244;
    } else {
      this.chartHeight = 466;
    }
    // this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      this.line.chartdata.labels = [];
      this.line.chartdata.datasets = [];
      this.pie.chartdata.labels = [];
      this.pie.chartdata.datasets.backgroundColor = [];
      this.pie.chartdata.datasets.data = [];
      this.delivered = 0;
      this.undelivered = 0;
      this.received = 0;
      this.failed = 0;
      this.sent = 0;
      this.total = 0;
      this.read = 0;
      this.accepted = 0;

      const unit = this.form.time_window === 'daily' ? 'day' : 'hour';
      this.line.options.scales.xAxes[0].time.unit = unit;

      const filter = {
        filters: this.filters,
        ...this.form,
        group_by: 'status',
        direction: 'outbound',
      };
      console.log(JSON.stringify(filter));
      WhatsService.getInsights({
        filters: this.filters,
        ...this.form,
        group_by: 'status',
        direction: 'outbound',
      })
        .then(
          (response) => {
            this.fetched = true;
            response.forEach((element) => {
              Object.keys(element.values).forEach((status, i) => {
                const value = element.values[status];
                if (!this.line.chartdata.datasets[i]) {
                  let color = '';
                  let label = '';
                  console.log(status);
                  if (status === 'delivered') {
                    color = '#2fbfa0';
                    label = this.$tc('generic-str.delivered', 2);
                  } else if (status === 'undelivered') {
                    color = '#15182b';
                    label = this.$tc('generic-str.unavailable', 2);
                  } else if (status === 'failed') {
                    color = '#ff5c75';
                    label = this.$t('generic-str.status.lbl-errors');
                  } else if (status === 'sent') {
                    color = '#399AF2';
                    label = this.$tc('generic-str.sent-a', 2);
                  } else if (status === 'read') {
                    color = '#662FBF';
                    label = this.$tc('generic-str.read-a', 2);
                  }
                  if (status === 'accepted') {
                    color = '#cccccc';
                    label = this.$tc('generic-str.status.lbl-pending', 1);
                  }
                  if (status === 'sending') {
                    color = '#cccccc';
                    label = this.$tc('generic-str.status.lbl-pending', 1);
                  }
                  if (status === 'received') {
                    color = '#2fbfa0';
                    label = this.$t('sms.infos.incoming-component.received');
                  }
                  this.line.chartdata.datasets[i] = {
                    label,
                    borderColor: color,
                    data: [value],
                    fill: false,
                    lineTension: 0.1,
                  };
                  this.pie.chartdata.labels.push(label);
                  this.pie.chartdata.datasets[0].backgroundColor[i] = color;
                  this.pie.chartdata.datasets[0].data[i] = value;
                } else {
                  this.line.chartdata.datasets[i].data.push(value);
                  this.pie.chartdata.datasets[0].data[i] += value;
                }
                if (status === 'delivered') {
                  this.delivered += value;
                } else if (status === 'undelivered') {
                  this.undelivered += value;
                } else if (status === 'failed') {
                  this.failed += value;
                } else if (status === 'received') {
                  this.received += value;
                } else if (status === 'sent') {
                  this.sent += value;
                } else if (status === 'read') {
                  this.read += value;
                } else if (status === 'accepted') {
                  this.accepted += value;
                }
                this.total += value;
              });
              this.line.chartdata.labels.push(element.date_time);
            });
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    changeChart() {
      this.type = this.type === 'pie' ? 'line' : 'pie';
    },
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
